.header {
  margin-left: 0px;
  padding-left: 5px;
  width: 100%;
  min-height: 5rem;
  background-color: var(--bright-blue);
  color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: unset;
  padding: 5px 10px;
}

.header img {
  max-width: 5rem;
  margin: 0 3px 0 0;
  max-height: 5rem;
}

.header h2 {
  font-size: 5.2vmax;
  text-align: center;
  font-family: 'Bangers', cursive;
  margin: 0 5px;
  letter-spacing: 1px;
  font-weight: bold;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1%;
  padding: 0% 2%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.cards h6 {
  width: 90%;
  font-size: 1.2rem;
  font-family: 'Livvic', sans-serif;
  font-weight: 500;
  text-overflow: ellipsis;
  color: var(--bright-blue);
  margin: 10px;
}
.cards img {
  width: 10%;
  max-width: 150px;
}
.cards input {
  border-radius: 5px;
  margin-right: 15px;
}

.fullForm {
  padding-top: 1px;
  background-color: var(--black);
}