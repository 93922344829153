:root {
  --bright-blue: #02C1D4;
  --cool-blue: #07689F;
  --black: #222831;
  --white: #fafafa;
  --bright-red: #F8475E;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--white);
  font-family: 'Livvic', sans-serif;
  font-weight: 400;
  margin: 0;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Livvic', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}
a {
  text-decoration: none;
  color:inherit;
}