.contactPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  flex: 1;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: left; */
  margin: 5rem auto;
}

.contact h2 {
  color: var(--bright-blue);
  font-size: 3.5rem;
  font-family: 'Bangers';
  letter-spacing: 4px;
  opacity: 0.9;
}
.contact ul {
  padding:0;
}


.contact ul li {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  letter-spacing: 1.1px;
}



.contact a {
  text-decoration: none;
  color: var(--bright-red);
  font-weight: bold;
}

.contact a:hover {
  color: var(--black);
}
