.homePage .heroHeader {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-image: url(../assets/hero-photo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.heroHeader .tagBox h2 {
  color: var(--white);
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 4vmax;
}
.bodySection {
  display: flex;
  flex-flow: row wrap;
  max-width: 800px;
  margin: 2rem auto;
  justify-content: space-evenly;
  align-items: center;
}
.bodySection .imgWrapper {
  display: flex;
  max-width: 400px;
}

.bodySection img {
  width: 95%;
  margin: 0 auto;
  border-radius: 4px;
}
@media (max-width: 775px) {
  .bodySection img {
    height: 350px;
    width: 350px;
    object-fit: cover;
  }
}

.bookSection {
  display: flex;
  flex-direction: column;
  margin: 5rem auto 5rem;
  max-width: 400px;

  padding: 0 20px;
}

.bookSection h2 {
  font-weight: 700;
  letter-spacing: -1px;
  color: var(--black);
}

.bookSection p {
  margin-bottom: 32px;
}

.bookSection .bookingBottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.bookSection .bookingBottom h2 {
  font-weight: 700;
  margin-left: 20px;
  color: var(--black);
}

.bookButton {
  font: inherit;
  background-color: var(--bright-red);
  border: 1px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: var(--black);
  border-radius: 15px;
  cursor: pointer;
  vertical-align: center;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookButton h3 {
  font-family: 'Livvic';
  font-weight: 700;
  letter-spacing: 1px;
}
