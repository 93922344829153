.huntAtHomePage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  flex: 1;
}

.starting {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 2rem;
  align-items: center;
}

.mainLogo {
  width: 80%;
  max-width: 25rem;
  margin: 1rem;
}

.formOccasion {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.labelOccasion {
  font-size: 1.75rem;
  display: block;
  font-family: 'Bangers';
  color: var(--bright-blue);
  margin-top: 1rem;
  text-align: center;
}

.inputOccasion {
  margin-top: 1rem;
  width: 90%;
  max-width: 23rem;
  height: 3rem;
  font-family: inherit;
  font-size: 0.9rem;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.3rem;
  color: var(--bright-blue);
}

.checkbox a {
  margin-left: 0.5rem;
  color: var(--bright-blue);
  font-weight:600;
}
.checkbox a:hover {
  color: var(--bright-red);
}

.buttonOccasion {
  max-width: 11rem;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  background-color: var(--bright-blue);
  margin-top: 1rem;
  border-radius: 5px;
  font-size: 1.4rem;
  font-family: 'Bangers';
  letter-spacing: 2px;
  color: var(--white);
  border: solid 1px var(--bright-blue);
}
