.footer {
  background-color: var(--bright-blue);
  width: 100%;
  color: var(--white);
  position: relative;
  bottom: 0;
 
}


.footer h1 {
  padding: 10px 0;
  display:flex;
  justify-content: center;
  text-align: center;
  font-family: 'Livvic', sans-serif;
  font-weight: 500;
  font-size: 4.0vmax;
}

.footer .footerContent {
  max-width: 800px;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
}
.footerContent ul {
  font-weight: 500;
  padding: 0;
  margin: 1rem 0;
}
.footerContent ul li {
  margin-bottom: 5px;
}

.footerContent ul a {
    text-decoration: none;
    color: var(--white);
    font-weight: 500;
    letter-spacing: 1.1px;
  }
  .footerContent ul a:hover {
    color: var(--bright-red)
  }
