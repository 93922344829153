.header {
  width: 100%;
  height: 5rem;
  background-color: var(--bright-blue);
  color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px;
}
.header .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.logo {
  display: flex;
  align-items: center;
}
.logo img {
  width: 5.9rem;
}
.title {
  margin-left: 0;
  height: inherit;
  letter-spacing: 2px;
}
.header h3 {
  font-size: 4vmax;
  font-family: 'Bangers', cursive;
  min-width: 160px;
  text-align: center;
}
.header button {
  display: none;
  border: none;
  color: var(--white);
  font-weight: 700;
  font-size: 28px;
  padding-top: 7px;
  background-color: transparent;
}
.header .navLinks {
  /* display: none; */
}

.header ul {
  list-style: none;
  margin-right: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}

.header li {
  margin: 0 0.3rem;
  vertical-align: center;
}

.header a {
  text-decoration: none;
  color: var(--white);
  font-weight: bold;
  letter-spacing: 1.1px;
}

.header .navlinks {
  display: none;
}
.header a:hover {
  color: var(--bright-red);
}

.header button:hover {
  color: var(--black);
}
.imgHeader {
  width: 50%;
}

@media (max-width: 768px) {
  .header ul {
    display: flex;
    flex-flow: column nowrap;
  }
}

@media (max-width: 415px) {
  .header ul li {
    font-size: 14px;
  }
}