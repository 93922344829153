.partnersPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  flex: 1;
}

.partnersBox {
  display: flex;
  flex-direction: column;
  color: var(--bright-blue);
  align-items: center;
}
h1 {
  font-family: 'Bangers';
  letter-spacing: 1px;
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem;
  font-weight: 600;
}

.partnersBox img {
  margin-top: 1rem;
  width: 60%;
  min-width: 290px;
  max-width: 400px;
  border-radius: 5px;
}

ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.partnersBox li {
  padding: 5px;
  font-size: 1.5rem;
}
.tourWebsite {
  margin: 0 auto;
}

.partnersBox li.tourWebsite {
  font-size: 1rem;
}
.partnersBox a {
  text-decoration: none;
  color: var(--bright-red);
}

.partnersBox a:hover {
  color: var(--black);
}
