.alertPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  flex: 1;
}

.alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin:1rem auto;
  width: 80%;
  min-height: 60%;
  height: 300px;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #222831ea;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: .5rem;
  color: var(--bright-blue);
}

.alert h1 {
  font-size: 1.75rem;
  font-family: inherit;
  margin: 0;
}

.alert img {
  width: 30%;
  max-width: 25rem;
  border-radius: 50%;
}

.alert p {
  margin: 0;
  font-size: 1.25rem;
  
}

.alert button {
  background-color: var(--bright-red);
  border-style: none;
  border-radius: 5px;
  margin: 0;
  color: var(--white);
  font-size: 1.4rem;
  letter-spacing: 2px;
  font-family: "Bangers";
  padding: 10px 20px;
}
