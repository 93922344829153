:root {
  --color-header-bg: #334257;
  --color-header-font: #ff4761;
  --color-main-bg: #00e5ff;
  --color-main-font: #394d5c;
  --font-header: 'Permanent Marker', cursive;
}
/* version1.8 */
/* body {
  background-color: #00e5ff;
} */
.header {
  margin: 0;

  width: 100%;
  min-height: 5rem;
  background-color: #334257;
  color: #ff4761;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: unset;
  padding: 5px 10px;
}

.header img {
  max-width: 5rem;
  margin: 0;
  max-height: 5rem;
}

#bizLogo {
  border-radius: 50%;
  max-width: 4.5rem;
  max-height: 4.5rem;
}

.header h2 {
  margin: 0;
  font-size: 2.5vmax;
  text-align: center;
  font-family: 'Permanent Marker', cursive;
  letter-spacing: 1px;
  font-weight: bold;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1% 1% 0% 1%;
  padding: 0% 2%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.cards h6 {
  width: 100%;
  font-size: 1.2rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 600;
  text-overflow: ellipsis;
  color: #394d5c;
  margin: 10px;
}
.cards img {
  width: 10%;
  max-width: 150px;
}
.cards input {
  border-radius: 5px;
  margin-right: 15px;
}

.fullForm {
  border-radius: 3px;
  padding-top: 1px;
  background-color: #00e5ff;
  padding-bottom: 1%;
}
